<template>
  <section
    class="w-11/12 mt-2 border border-green-1000 overflow-hidden rounded-lg m-auto"
    :class="accordionClasses"
  >
    <header
      class="flex items-center py-2 px-3"
      :class="{ 'border-b border-green-1000 bg-gray-300': isOpen }"
    >
      <p class="w-3/12">{{ name }}</p>
      <p class="w-1/12 text-center">{{ workload }}</p>
      <p class="w-1/12 text-center">{{ performanceIndex }}</p>
      <p class="w-1/12 text-center">{{ score }}</p>
      <p class="w-2/12 text-center">{{ startedAt }}</p>
      <p class="w-2/12 text-center">{{ endedAt }}</p>
      <button
        type="button"
        class="w-2/12 py-1 border bg-green-1000 hover:bg-green-1001 focus:outline-none cursor-pointer text-white rounded-lg"
        @click="toggleAccordion"
      >
        {{ detailsButtonText }}
      </button>
    </header>
    <div class="flex justify-between p-4" v-show="isOpen">
      <div class="w-6/12 pr-4">
        <label class="text-xl">Conteúdo Programático</label>
        <p class="mt-2">{{ programmaticContent }}</p>
      </div>
      <div class="w-6/12 pl-4">
        <label class="text-xl">Objetivo</label>
        <p class="mt-2">{{ objective }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CertificateAccordion',
  props: {
    name: {
      required: true
    },
    workload: {
      required: true
    },
    performanceIndex: {
      required: true
    },
    score: {
      required: true
    },
    startedAt: {
      required: true
    },
    endedAt: {
      required: true
    },
    programmaticContent: {
      required: true
    },
    objective: {
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  },
  computed: {
    accordionClasses() {
      return {
        'is-closed': !this.isOpen,
        'is-primary': this.isOpen,
        'is-dark': !this.isOpen
      };
    },
    detailsButtonText() {
      return this.isOpen ? 'Fechar' : 'Ver detalhes';
    }
  }
};
</script>

<style scoped></style>
